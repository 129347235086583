import { cloneDeep } from 'lodash';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType, ListEntry } from '../../common';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { CompoundFeedDatabase, MasterDataOption, Maybe } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { ShrimpBaseline, ShrimpGrowingStageData } from '../../../../models/Baseline/ShrimpBaseline';
import { 
  ShrimpIntervention, 
  ShrimpInterventionFeed, 
  ShrimpStageData 
} from '../../../../models/Intervention/ShrimpIntervention';
import ShrimpFeedForm from './ShrimpFeedForm';

interface ShirmpFeedDialogProps {
  formType: FormType;
  stageIndex: number;
  formVisible: boolean;
  handleCancel: (param?: string | null) => void;
  baseline: ShrimpBaseline;
  compoundFeeds: Maybe<Array<MasterDataOption>>;
  singleIngredients: Maybe<Array<MasterDataOption>>;
  origins: ListEntry[];
}

const ShirmpFeedDialog = ({
  formType = FormType.Add,
  stageIndex = 0,
  formVisible,
  handleCancel,
  baseline,
  compoundFeeds,
  singleIngredients,
  origins,
}: ShirmpFeedDialogProps) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<ShrimpInterventionFeed>();
  const formTitle = intl.formatMessage({id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE'});
  const fc = useFormContext<ShrimpIntervention>();
  const getBaselineFeed = () => {
    const stageData = baseline.stages[stageIndex].stageData as ShrimpGrowingStageData;
    return stageData.feed;
  }

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...fc.getValues() } as ShrimpIntervention;
      const stageData = resetObject.stages?.[stageIndex]?.stageData as unknown as ShrimpStageData || {};
      if (resetObject?.stages?.[stageIndex]) resetObject.stages[stageIndex].stageData = stageData;
      if (stageData?.feed) {
        stageData.feed = currResetValue.current;
        fc.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) { handleCancel() }
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(fc.getValues(`stages.${stageIndex}.stageData.feed`)) || {totalFeedIntake: 0};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      variant="ultrawide"
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      datasetType="intervention"
    >
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <DsmFieldset
          style={{ width: '58%', marginTop: `var(--dsm-spacing-px-4)` }}
        >
          <ReactHookDsmSelect
            name="databaseFoundation"
            defaultValue={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
            label={`${intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION'})}`}
            disabled
            options={getDatabaseOptions() as []}
            tooltip={intl.formatMessage({id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP'})}
          />
        </DsmFieldset>
      </DsmGrid>
      <ShrimpFeedForm
        formType={formType}
        itemIndex={stageIndex}
        databaseFoundation={baseline?.info?.databaseFoundation || CompoundFeedDatabase.Gfli}
        feedBaseline={getBaselineFeed()}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        origins={origins}
        fieldItemPrefix={`stages.${stageIndex}.stageData.feed`}
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleCancel}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default ShirmpFeedDialog;
