import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
    defaultUnits,
    defaultUnitsV2,
    unitLong,
} from '../../../../utils/unit-utils';
import lookupValues from '../../../../helpers/lookupValues';
import {
    AnimalType,
    CompoundFeedDatabase,
} from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import SustellResourceAdditionalItem from '../../../../../modules/Helpers/SustellResourceAdditionalItem';
import { isMarineFish, isShrimp } from '../../../../helpers/animals';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

interface ResourcesProps {
    formType: FormType;
    inputType: string;
    databaseFoundation?: CompoundFeedDatabase;
    animalType?: AnimalType;
    required?: boolean;
    isBaselineDialog?: boolean;
    onChange?: () => void;
    editEnabled?: boolean;
    baseline: ShrimpBaseline;
}

const ShrimpResourcesDialog: FC<ResourcesProps> = ({
    formType = FormType.View,
    inputType,
    databaseFoundation,
    animalType,
    required = false,
    isBaselineDialog = false,
    editEnabled = false,
    baseline,
    onChange,
}) => {
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;
    const userProfile = UserProfilePrefs.getInstance();
    const userUOM = userProfile.getUserUnitPrefs();
    const resourcesData = baseline?.resourceUse;

    const { control, getValues } = useFormContext();

    const {
        fields: selfGeneratedRenewables,
        append: appendSelfGeneratedRenewables,
        remove: removeSelfGeneratedRenewablesItem,
    } = useFieldArray({
        name: `${inputType}.selfGeneratedRenewables`,
        control,
        keyName: 'keyId',
    });

    const {
        fields: energyTypes,
        append: appendAnotherEnergyType,
        remove: removeAnotherEnergyTypeItem,
    } = useFieldArray({
        name: `${inputType}.energyTypes`,
        control,
        keyName: 'keyId',
    });

    const isFeed = inputType === 'on_site_activities_input';

    const isV2Changes =
        (databaseFoundation && databaseFoundation !== CompoundFeedDatabase.Afp_5) ||
        animalType;

    const removeSelfGeneratedRenewables = (index: number) => {
        removeSelfGeneratedRenewablesItem(index);
        // To enforce draft saving set touched to true
        control.updateFormState({
            touched: { [`${inputType}.selfGeneratedRenewables`]: true },
        });
    };

    const removeAnotherEnergyType = (index: number) => {
        removeAnotherEnergyTypeItem(index);
        control.updateFormState({
            touched: { [`${inputType}.energyTypes`]: true },
        });
    };

    // CF form fields have _ naming
    const getFormFieldName = (fieldName: string): string => {
        if (isFeed) {
            return `${inputType}.${fieldName.replace('Use', '_use')}`;
        }
        return `${inputType}.${fieldName}`;
    };

    return (
        <>
            <DsmGrid
                className={classes.dsmGridOneColumn}
                style={{ rowGap: 0 }}
            >
                <DsmGrid className={classes.dsmGridTwoColumn}                >
                    {!editEnabled && !resourcesData?.electricityUse ? (
                        <ReactHookDsmInput
                            name={getFormFieldName('originalElectricityUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY', })}
                            disabled={editEnabled === false}
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP' })}
                            type="number"
                            adornment={
                                userUOM?.unitElectricityUse
                                    ? unitLong(userUOM.unitElectricityUse)
                                    : defaultUnitsV2.unitElectricityUse
                            }
                            required={required}
                            changeHandler={() => {
                                if (onChange) onChange();
                            }}
                            defaultValue={resourcesData?.electricityUse as string}
                        />) : (
                        <RowTextFieldWithMetrics
                            name={getFormFieldName('electricityUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY', })}
                            type="number"
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP' })}
                            metricUnit=''
                            baseline={resourcesData?.electricityUse}
                            disabled={formType === FormType.View || !editEnabled}
                            displayTypeSelect={editEnabled}
                        />
                    )}
                </DsmGrid>

                {selfGeneratedRenewables?.map((item, index) => (
                    <div key={item.keyId}>
                        <DsmGrid
                            style={{
                                grid: `auto/1fr ${isBaselineDialog ? '0.88fr' : '1fr'
                                    } 32px`,
                                gap: 'var(--dsm-spacing-px-4)',
                                alignItems: 'start',
                                alignContent: ' space-between',
                                padding: '0px',
                            }}
                        >
                            <SustellResourceAdditionalItem
                                labelInput={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE',
                                })}
                                labelSelect={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE',
                                })}
                                placeholderSelect=""
                                tooltipType={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP',
                                })}
                                tooltipUse={intl.formatMessage({
                                    id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE.TOOLTIP',
                                })}
                                index={index}
                                formType={formType}
                                defaultValueAmount={item?.value}
                                deafultValueType={item?.type}
                                required={required}
                                amountFieldName="value"
                                fieldItemPrefix={`${inputType}.selfGeneratedRenewables`}
                                isFeeds={isFeed}
                                isRenewables
                                onChange={onChange}
                            />

                            {formType !== FormType.View &&
                                removeSelfGeneratedRenewables &&
                                selfGeneratedRenewables.length > 0 && (
                                    <DsmButton
                                        variant="text"
                                        destructive
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            padding: '0px',
                                            marginTop: '24px',
                                        }}
                                        onClick={() => {
                                            removeSelfGeneratedRenewables(index);
                                            if (onChange) onChange();
                                        }}
                                    >
                                        <DsmIcon
                                            name="general/trash-01"
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                            }}
                                        />
                                    </DsmButton>
                                )
                            }
                        </DsmGrid>
                    </div>
                ))}

                {editEnabled ? (
                        <DsmButton
                            variant="text"
                            onClick={() => {
                                appendSelfGeneratedRenewables({ type: '', value: '' });
                                if (onChange) onChange();
                            }}
                            disabled={
                                formType === FormType.View ||
                                selfGeneratedRenewables.length >=
                                lookupValues.selfGeneratedRenewablesList.length
                            }
                        >
                            <DsmIcon slot="before" name="general/plus-circle" />
                            {intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_BUTTON',
                            })}
                        </DsmButton>
                ) : (<></>)}
            </DsmGrid>

            
            <br />

            <DsmGrid className={classes.dsmGridTwoColumn}                >
                {!editEnabled && !resourcesData?.gasUse ? (
                    <ReactHookDsmInput
                        name={getFormFieldName('originalGasUse')}
                        label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS', })}
                        tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP' })}
                        type="number"
                        adornment={
                            userUOM?.unitNaturalGasUse
                                ? unitLong(userUOM.unitNaturalGasUse)
                                : defaultUnitsV2.unitNaturalGasUse
                        }
                        disabled
                        required={required}
                        changeHandler={() => {
                            if (onChange) onChange();
                        }}
                        defaultValue={resourcesData?.gasUse as string}
                    />) : (
                    <>
                        <RowTextFieldWithMetrics
                            name={getFormFieldName('gasUse')}
                            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS', })}
                            type="number"
                            tooltip={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP' })}
                            metricUnit=''
                            baseline={resourcesData?.gasUse}
                            disabled={formType === FormType.View || !editEnabled}
                            displayTypeSelect={editEnabled}
                        />
                    </>)}
            </DsmGrid>

            {energyTypes?.map((item, index) => (
                <div key={item.keyId}>
                    <DsmGrid
                        style={{
                            grid: `auto/1fr ${isBaselineDialog ? '0.88fr' : '1fr'
                                } 32px`,
                            gap: 'var(--dsm-spacing-px-4)',
                            alignItems: 'start',
                            alignContent: ' space-between',
                            padding: '0px',
                        }}
                    >
                        <SustellResourceAdditionalItem
                            labelInput={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE',
                            })}
                            labelSelect={intl.formatMessage({
                                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE',
                            })}
                            placeholderSelect=""
                            tooltipType={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.TOOLTIP',
                            })}
                            tooltipUse={intl.formatMessage({
                                id: isShrimp(animalType)
                                    ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.SHRIMP.TOOLTIP'
                                    : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.TOOLTIP',
                            })}
                            index={index}
                            formType={formType}
                            defaultValueAmount={item?.value}
                            deafultValueType={item?.type}
                            required={required}
                            amountFieldName="value"
                            fieldItemPrefix={`${inputType}.energyTypes`}
                            isFeeds={isFeed}
                            unavailableOptions={
                                !isMarineFish(animalType) ? [] : ['dieselUse']
                            }
                            onChange={onChange}
                        />
                        {formType !== FormType.View &&
                            removeAnotherEnergyType &&
                            energyTypes.length > 0 && (
                                <DsmButton
                                    variant="text"
                                    destructive
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        padding: '0px',
                                        marginTop: '24px',
                                    }}
                                    onClick={() => {
                                        removeAnotherEnergyType(index);
                                        if (onChange) onChange();
                                    }}
                                >
                                    <DsmIcon
                                        name="general/trash-01"
                                        style={{
                                            position: 'absolute',
                                            right: '0',
                                        }}
                                    />
                                </DsmButton>
                            )}
                    </DsmGrid>
                </div>
            ))}

            {editEnabled ? (
                <DsmButton
                    variant="text"
                    onClick={() => {
                        appendAnotherEnergyType({ type: '', value: '' });
                        if (onChange) onChange();
                    }}
                    disabled={
                        formType === FormType.View ||
                        energyTypes.length >=
                        (!isMarineFish(animalType)
                            ? lookupValues.energyTypesList.length
                            : lookupValues.energyTypesList.length - 1)
                    }
                >
                    <DsmIcon slot="before" name="general/plus-circle" />
                    {intl.formatMessage({
                        id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY.TYPE_BUTTON',
                    })}
                </DsmButton>
            ) : (<></>)}

            <DsmGrid
                className={classes.dsmGridTwoColumn}
                // eslint-disable-next-line no-nested-ternary
                style={{
                    marginTop: `var(--dsm-spacing-px-6)`,
                    width: isV2Changes ? (isBaselineDialog ? '' : '75%') : '62%',
                }}
            >
                {!isV2Changes && (
                    <ReactHookDsmInput
                        name={getFormFieldName('heatUse')}
                        label={intl.formatMessage({
                            id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
                        })}
                        tooltip={intl.formatMessage({
                            id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
                        })}
                        type="number"
                        adornment={
                            userUOM?.unitHeatUse
                                ? unitLong(userUOM.unitHeatUse)
                                : defaultUnitsV2.unitHeatUse
                        }
                        disabled={editEnabled === false}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        defaultValue={getValues(getFormFieldName('heatUse')) || ''}
                    />
                )}
            </DsmGrid>
        </>
    );
};

export default ShrimpResourcesDialog;
