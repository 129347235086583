import { FC, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { Maybe } from 'graphql/jsutils/Maybe';

import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { FormType } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { InputAnimalOrigin } from '../../../../../../graphql/generated/blonk/pigs';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmRadioGroup, {
    RadioGroupOption,
} from '../../../../../modules/Helpers/ReactHookDsmRadioGroup';
import RowTextFieldWithMetrics from '../../../../../modules/Farms/Intervention/RowControlledTextFieldWithMetrics2';
import { AnimalType } from '../../../../../../graphql/types';
import { ShrimpBaseline } from '../../../../models/Baseline/ShrimpBaseline';
import { ShrimpOutput } from '../../../../models/Intervention/ShrimpIntervention';

interface ShrimpOutputFormProps {
    stageIndex?: number;
    itemIndex?: number;
    formType: FormType;
    editEnabled?: boolean;
    removeHandler?: Maybe<Function>;
    animalType: AnimalType.LitopenaeusVannamei | AnimalType.PenaeusMonodon;
    baseline: ShrimpBaseline;
}

const ShrimpOutputForm: FC<ShrimpOutputFormProps> = ({
    stageIndex = 0,
    itemIndex = 0,
    formType,
    editEnabled = false,
    removeHandler,
    animalType,
    baseline,
}) => {
    const intl = useIntl();
    const classes = processAndStageStylesV2() as CSSClassesList;

    const formContext = useFormContext();

    const fieldItemPrefix = `stages.${stageIndex}.stageData.output.outputs.${itemIndex}`;
    const outputData = baseline.stages[stageIndex].stageData.output.outputs[itemIndex];

    const outputsLengthWatch = (
        useWatch({
            control: formContext.control,
            name: `stages.${stageIndex}.stageData.output.outputs`,
        }) as ShrimpOutput[]
    )?.length;

    const outputsLength = useMemo(
        () =>
            (
                formContext.getValues(
                    `stages.${stageIndex}.stageData.output.outputs`
                ) as ShrimpOutput[]
            )?.length,
        [outputsLengthWatch]
    );

    const inputTotalPondArea = baseline.stages[stageIndex].stageData.input.totalPondArea as number;

    const totalWeight = outputData.totalWeight as number;

    const weightPerHectare = useMemo(
        () =>
            inputTotalPondArea ? (totalWeight / inputTotalPondArea).toFixed(2) : 0,
        [totalWeight]
    );

    const yesValue = intl.formatMessage({
        id: 'GENERAL.YES',
    });

    const noValue = intl.formatMessage({
        id: 'GENERAL.NO',
    });

    const soldExternallyOptions: RadioGroupOption[] = [
        { value: yesValue, label: yesValue },
        { value: noValue, label: noValue },
    ];

    return (
        <>
            <input
                ref={formContext.register()}
                type="hidden"
                name={`${fieldItemPrefix}.origin`}
                value={InputAnimalOrigin.BackgroundData}
            />
            <input
                ref={formContext.register()}
                type="hidden"
                name={`${fieldItemPrefix}.id`}
                value={formContext.getValues(`${fieldItemPrefix}.id`) as string}
            />
                <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
                    {removeHandler && editEnabled && (
                        <DsmButton
                            variant="text"
                            style={{ position: 'relative', width: '100%' }}
                            onClick={() => removeHandler(itemIndex)}
                        >
                            <DsmIcon
                                name="general/x-close"
                                style={{
                                    position: 'absolute',
                                    color: 'var(--dsm-color-neutral-darker',
                                    right: '0',
                                }}
                            />
                        </DsmButton>
                    )}
                    <DsmGrid className={classes.dsmGridOneColumnWithBorder}>
                        {(formType !== FormType.View && removeHandler) && (
                            <DsmButton
                                variant="text"
                                disabled
                                style={{ position: 'relative', width: '100%' }}
                                onClick={() => removeHandler(itemIndex)}
                            >
                                <DsmIcon
                                    name="general/x-close"
                                    style={{
                                        position: 'absolute',
                                        color: 'var(--dsm-color-neutral-darker',
                                        right: '0',
                                    }}
                                />
                            </DsmButton>
                        )}
                        <DsmGrid className={classes.dsmGridTwoColumn}>
                            {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.totalWeight`) ? (

                                <ReactHookDsmInput
                                    name={`${fieldItemPrefix}.originalPrice`}
                                    label={`${intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE',
                                    })}`}
                                    tooltip={intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.TOOLTIP',
                                    })}
                                    adornment={intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.PLACEHOLDER',
                                    })}
                                    type="number"
                                    disabled
                                    required={outputsLength > 1}
                                    defaultValue={outputData?.price as string}
                                />) : (
                                <RowTextFieldWithMetrics
                                    name={`${fieldItemPrefix}.price`}
                                    label={intl.formatMessage({ id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE' })}
                                    type="number"
                                    tooltip={intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.PRICE.TOOLTIP',
                                    })}
                                    metricUnit=''
                                    baseline={outputData?.price}
                                    disabled={formType === FormType.View || !editEnabled}
                                    displayTypeSelect={editEnabled}
                                />
                            )}

                            <ReactHookDsmRadioGroup
                                name={`${fieldItemPrefix}.originalSoldExternally`}
                                label={`${intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.SOLD_EXTERNALLY',
                                })}`}
                                options={soldExternallyOptions}
                                disabled
                                required
                                defaultValue={
                                    outputData?.soldExternally as string
                                }
                            />
                        </DsmGrid>

                        {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.totalWeight`) ? (
                            <DsmGrid className={classes.dsmGridTwoColumn}                        >
                                <ReactHookDsmInput
                                    name={`${fieldItemPrefix}.originalTotalWeight`}
                                    label={`${intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT',
                                    })}`}
                                    tooltip={intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.TOOLTIP',
                                    })}
                                    adornment={intl.formatMessage({
                                        id: 'BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.PLACEHOLDER',
                                    })}
                                    type="number"
                                    disabled
                                    required
                                    helperText={intl.formatMessage(
                                        { id: 'BASELINE.FORM.OUTPUT.SHRIMP.AVERAGE_WEIGHT_HECTARE' },
                                        {
                                            weight: weightPerHectare,
                                        }
                                    )}
                                    defaultValue={
                                        outputData?.totalWeight as string
                                    }
                                />
                            </DsmGrid>) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.totalWeight`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT' })}
                                type="number"
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.TOTAL_WEIGHT.TOOLTIP',
                                })}
                                metricUnit=''
                                baseline={outputData?.totalWeight}
                                disabled={formType === FormType.View || !editEnabled}
                                displayTypeSelect={editEnabled}
                            />
                        )}

                        {!editEnabled && !formContext.getValues(`${fieldItemPrefix}.proteinContent`) ? (
                            <ReactHookDsmInput
                                name={`${fieldItemPrefix}.originalProteinContent`}
                                label={`${intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT',
                                })}`}
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.TOOLTIP',
                                })}
                                adornment={intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.PLACEHOLDER',
                                })}
                                type="number"
                                disabled
                                defaultValue={
                                    outputData?.proteinContent as string
                                }
                            />) : (
                            <RowTextFieldWithMetrics
                                name={`${fieldItemPrefix}.proteinContent`}
                                label={intl.formatMessage({ id: 'BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT' })}
                                type="number"
                                tooltip={intl.formatMessage({
                                    id: 'BASELINE.FORM.OUTPUT.SHRIMP.PROTEIN_CONTENT.TOOLTIP',
                                })}
                                metricUnit=''
                                baseline={outputData?.proteinContent}
                                disabled={formType === FormType.View || !editEnabled}
                                displayTypeSelect={editEnabled}
                            />
                        )}

                    </DsmGrid>
                </DsmGrid>
            </>
    );
};

export default ShrimpOutputForm;
